import "./ProfilePic.css";

function ProfilePic(props: any) {
  return (
    <div className="ProfilePic">
      <img src="profile.jpg" alt="Cozmin Ungureanu" />
    </div>
  );
}

export default ProfilePic;
